/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Head from 'next/head';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { countryCodes } from '@/constants/locale';
import { HomePageTemplate } from '../components/HomePageTemplate';
import { useProductDataTopNavMenu } from '@/features/topNav/api/getProductDataTopNavMenu';
import { useDeviceType } from '@/utils/useDeviceType';

type Props = {
  isPreview?: boolean;
};

type SEOLinks = {
  canonical: string;
  alternates: Array<{
    key: string;
    lang: string;
    url: string;
  }>;
};

const L1CategoryLists = () => {
  const { data } = useProductDataTopNavMenu();

  return (
    <div>
      <nav>
        <ul>
          {data?.rootCategories.map((category) => {
            const popularCategories = category.popularCategories.map((popularCategory) => (
              <li key={popularCategory.NId}>
                <a href={popularCategory.seoUrl}>{popularCategory.label}</a>
              </li>
            ));
            const childCategories = category.chldCategories.map((childCategory) => (
              <li key={childCategory.NId}>
                <a href={childCategory.seoUrl}>{childCategory.label}</a>
              </li>
            ));
            return [popularCategories, childCategories];
          })}
        </ul>
      </nav>
    </div>
  );
};

const us = 'https://www.autozone.com';
const mx = 'https://www.autozone.com.mx';
const br = 'https://www.autozone.com.br';

const localeLinks: Record<Locale, SEOLinks | undefined> = {
  'en-US': {
    canonical: us,
    alternates: [
      {
        key: 'linkAlternateObj',
        lang: countryCodes.mx,
        url: mx,
      },
      {
        key: 'linkAlternateObj_BR',
        lang: countryCodes.ptBr,
        url: br,
      },
    ],
  },
  'es-MX': {
    canonical: mx,
    alternates: [
      {
        key: 'linkAlternateObj',
        lang: countryCodes.us,
        url: us,
      },
      {
        key: 'linkAlternateObj_BR',
        lang: countryCodes.ptBr,
        url: br,
      },
    ],
  },
  'pt-BR': {
    canonical: br,
    alternates: [
      {
        key: 'linkAlternateObj',
        lang: countryCodes.us,
        url: us,
      },
      {
        key: 'linkAlternateObj_MX',
        lang: countryCodes.mx,
        url: mx,
      },
    ],
  },
};

export const HomePage = ({ isPreview }: Props) => {
  useAnalytics();
  const deviceType = useDeviceType();
  const locale = useLocale();

  const links = localeLinks[locale];

  return (
    <>
      <Head>
        {links && <link key="linkCanonical" rel="canonical" href={links.canonical} />}
        <link key="linkAlternateDefault" rel="alternate" hrefLang="x-default" href={us} />
        {links?.alternates.map((link) => (
          <link key={link.key} rel="alternate" hrefLang={link.lang} href={link.url} />
        ))}
      </Head>
      {deviceType === 'bot' ? <L1CategoryLists /> : null}
      <HomePageTemplate isPreview={isPreview} />
    </>
  );
};
