/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Picture } from '@/components/Picture/Picture';
import { CMSLink } from '../../CMSLink/CMSLink';
import cx from 'classnames';
import { Text, View } from '@az/starc-ui';
import { useResponsiveImageData } from '../../../hooks/useResponsiveImageData';
import { ContentStackGridBanner } from '../../../interface';
import styles from './styles.module.scss';

const sizePerDeviceForSixItems = {
  mobile: {
    width: 320,
    height: 246,
  },
  tablet: {
    width: 156,
    height: 174,
  },
  desktop: {
    width: 242,
    height: 209,
  },
};

const sizePerDeviceForFourItems = {
  mobile: {
    width: 320,
    height: 377,
  },
  tablet: {
    width: 242,
    height: 179,
  },
  desktop: {
    width: 320,
    height: 377,
  },
};

type Props = {
  item: NonNullable<ContentStackGridBanner['grid_content']>[0];
  layout: 'Four' | 'Six';
};

export const GridBannerItem = ({ item, layout }: Props) => {
  const { image, link, heading, sub_heading } = item;
  const sizePerDevice = layout === 'Four' ? sizePerDeviceForFourItems : sizePerDeviceForSixItems;
  const imageData = useResponsiveImageData({ image, sizePerDevice });
  const fourProductsLayout = layout === 'Four';

  return (
    <CMSLink
      to={link}
      className={cx(
        styles.gridBannerItemWrapper,
        fourProductsLayout ? styles.itemFourElementsGrid : styles.itemSixElementsGrid
      )}
    >
      <View className={cx(styles.gridBannerItem)}>
        <View className={cx(styles.imageWrapper, styles.item)}>
          <Picture
            altText={image?.image_alt_text ?? ''}
            mobile={imageData.mobile}
            tablet={imageData.tablet}
            desktop={imageData.desktop}
            imageClassName={styles.productImage}
          />
        </View>
        {heading && (
          <Text className={styles.item} size="087" weight="bold" as="h3">
            {heading}
          </Text>
        )}
        {sub_heading && (
          <Text className={styles.item} size="075" as="p">
            {sub_heading}
          </Text>
        )}
      </View>
    </CMSLink>
  );
};
