/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Legal } from '@/components/AZCustomComponent/Legal';
import Carousel from '@/components/Carousel';
import { trackHomeCarouselInteraction } from '@/utils/analytics/track/trackHomeCarouselInteraction';
import type {
  ContentStackHeroComponent,
  ContentStackLegal,
} from '@/features/contentstack/interface';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useInteractionCheck } from '@/hooks/useInteractionCheck';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Hidden, Text, View } from '@az/starc-ui';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './HomepageHero.module.scss';
import { mapContentstackLegalValues } from '@/utils/mapContentstackLegalValues';
import { FeaturedIcon } from './FeaturedIcon';
import { CarouselItem } from '../../interface';
import { CarouselImage } from './CarouselImage';
import { Icon } from '@az/starc-ui';
import { ArrowRight } from '@az/starc-ui-icons';
import { useCitrusHomepageData } from '@/features/citrus/api/getCitrusHomepageData';
import { reportCitrusImpression } from '@/features/citrus/utils/reportCitrusImpression';
import { mapCitrusBannerToContentStackBanner } from '@/features/citrus/utils/mapCitrusBannerToContentStackBanner';
import { isLegalDefined } from '@/features/contentstack/utils/isLegalDefined';
import { isCitrusBannerWithLink } from '@/features/citrus/utils/isCitrusBannerWithLink';
import { useFeatureFlag } from '@/features/globalConfig';
import { ModalLink } from '@/components/ModalLink/ModalLink';
import { isModalLink } from '@/features/contentstack/utils/isModalLink';

type Props = {
  options: ContentStackHeroComponent;
};

export const HomepageHero = ({ options }: Props) => {
  const { left, right } = options;
  const justMounted = useRef(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startAutoRotation, setStartAutoRotation] = useState(false);
  const [setFocusToAnchorTag, setSetFocusToAnchorTag] = useState(false);
  const [dataToRender, setDataToRender] = useState<CarouselItem[]>(left?.carousel ?? []);
  const [qssDataMerged, setQssDataMerged] = useState(false);
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const citrusHeroEnabled = useFeatureFlag('IS_CITRUS_HERO_ENABLED') === 'true';
  const { data: citrusData } = useCitrusHomepageData({
    enabled: citrusHeroEnabled,
  });

  const [isLegalCopyModalOpened, setIsLegalCopyModalOpened] = useState(() => false);
  const { data: headerData } = useHeaderData();
  const vehicleId = headerData?.vehicleMap.catalogVehicleId;
  const { query } = useRouter();

  const router = useRouter();

  // Only enable carousel autorotate when a user interacts with the site
  const userInteracted = useInteractionCheck();

  const getCitrusHerobanner = useCallback((): CarouselItem[] => {
    const carouselBanner =
      citrusData?.ads.find((item) => item.slotId === 'carousel-banner')?.banners ?? [];
    const mappedCarouselBanners = carouselBanner
      .map((item) => {
        if (!isCitrusBannerWithLink(item)) return null;
        const cmsBanner = mapCitrusBannerToContentStackBanner(item);
        return {
          image: cmsBanner.image,
          link: cmsBanner.link ?? '',
          legal: cmsBanner.legal,
          citrusInfo: {
            citrusAdId: item.citrusAdId,
          },
        };
      })
      .filter(Boolean);
    return mappedCarouselBanners.slice(0, 5) as CarouselItem[];
  }, [citrusData?.ads]);

  useEffect(() => {
    if (justMounted.current) {
      justMounted.current = false;
      return;
    }

    setStartAutoRotation(!isLegalCopyModalOpened && userInteracted);
  }, [isLegalCopyModalOpened, userInteracted]);

  useEffect(() => {
    startAutoRotation && setFocusToAnchorTag && setSetFocusToAnchorTag(false);
  }, [setFocusToAnchorTag, startAutoRotation]);

  useEffect(() => {
    let waitForBannersTimeout: NodeJS.Timeout;
    const leftCarousel = left?.carousel ?? [];

    const numToSlice = 1;
    const firstThreeAZPromos = leftCarousel.slice(0, numToSlice);
    const remainingAZPromos = leftCarousel.slice(numToSlice);
    const citrusHeroBanners = citrusHeroEnabled ? getCitrusHerobanner() : [];

    setDataToRender([...firstThreeAZPromos, ...citrusHeroBanners, ...remainingAZPromos]);
    setQssDataMerged(true);

    return () => {
      clearTimeout(waitForBannersTimeout);
    };
  }, [left?.carousel, query, vehicleId, isPhone, isTablet, getCitrusHerobanner, citrusHeroEnabled]);

  const stopRotate = () => {
    if (startAutoRotation) {
      setStartAutoRotation(false);
    }
  };
  const startRotate = () => {
    if (!startAutoRotation) {
      setStartAutoRotation(true);
    }
  };

  const handleDotClick = (index: number, isKeyUp: boolean) => {
    setCurrentSlide(index);
    const clickedData = dataToRender[index];

    trackHomeCarouselInteraction(
      clickedData.image?.image_alt_text ?? '',
      index,
      `Home Hero Bubble: Rank ${index}`
    );

    isKeyUp ? setSetFocusToAnchorTag(true) : setSetFocusToAnchorTag(false);
  };

  const handleImageChange = (index: number) => {
    setCurrentSlide(index);
    const currentItem = dataToRender[index];
    if ('citrusInfo' in currentItem && currentItem.citrusInfo?.citrusAdId) {
      void reportCitrusImpression(currentItem.citrusInfo.citrusAdId);
    }
  };

  const handleToggleLegalModal = (isOpened: boolean) => {
    setIsLegalCopyModalOpened(isOpened);
  };

  const renderHeroContentBlock = () => {
    return dataToRender.map((colItem, index) => (
      <CarouselImage
        key={colItem.image?.image_url ?? index}
        blockItem={colItem}
        itemIndex={index}
        tabindex={0}
        isCurrentSlide={currentSlide === index}
        setFocusToAnchorTag={setFocusToAnchorTag}
      />
    ));
  };

  const getBlockItembyId = (id: number) => {
    return dataToRender.find((item, index) => {
      if (index == id) {
        return item;
      }
    });
  };

  const renderLegalComponent = () => {
    const blockItem = getBlockItembyId(currentSlide);
    const legal = blockItem?.legal;

    const isWithLegalText =
      !!blockItem?.legal && ('text' in blockItem.legal || 'legalText' in blockItem.legal);
    const hasLegalDefinedValue =
      (legal && 'legalText' in legal && legal.legalText) || isLegalDefined(legal);

    if (blockItem?.legal && hasLegalDefinedValue) {
      return (
        <div className={styles.legacyBanner}>
          <Legal
            content={{
              ...mapContentstackLegalValues(blockItem.legal as ContentStackLegal),
              imageLink: blockItem.link,
            }}
            handleToggleLegalModal={handleToggleLegalModal}
            isWithLegalText={isWithLegalText}
            isHeroCarouselBanner
            isCMSHeroBanner
            singleLine
          />
        </div>
      );
    }
  };

  return (
    <>
      <View
        attributes={{ id: 'superHeroContainer' }}
        direction={{ s: 'column', m: 'row' }}
        align="stretch"
        gap={0}
        className={styles.heroContainer}
      >
        <View.Item columns={{ s: 12, m: 6, l: 6 }}>
          <View
            className={styles.slider}
            height="100%"
            attributes={{
              onMouseEnter: stopRotate,
              onFocus: stopRotate,
              onMouseLeave: !isPhone && !isLegalCopyModalOpened ? startRotate : undefined,
              onBlur: !isLegalCopyModalOpened ? startRotate : undefined,
            }}
          >
            <Carousel
              onImageChange={handleImageChange}
              autoRotationEnabled={startAutoRotation}
              handleDotClick={handleDotClick}
              showCarouselDots={qssDataMerged}
              isLoadingCarouselDots={!qssDataMerged}
              showArrows={!isPhone && !isTablet}
            >
              {renderHeroContentBlock()}
            </Carousel>
          </View>
          <Hidden hide={{ m: true }}>
            <View className={styles.legalContainer}>{renderLegalComponent()}</View>
          </Hidden>
        </View.Item>
        <View.Item columns={{ s: 12, m: 6, l: 6 }} grow gapBefore={{ s: 0, m: 2, l: 4 }}>
          <View
            direction="column"
            gap={{ s: 2, l: 4 }}
            align="stretch"
            height="100%"
            className={styles.featuredContainer}
          >
            {right?.feature_cta?.map((ctaAttributes) => {
              const content = (
                <View
                  padding={{ s: 2, l: 6 }}
                  direction="row"
                  align="center"
                  height="100%"
                  className={styles.singleCtaInnerContainer}
                >
                  <View.Item>
                    {ctaAttributes.image && <FeaturedIcon image={ctaAttributes.image} />}
                  </View.Item>
                  <View.Item grow>
                    <View gap={1}>
                      <Text
                        as="h3"
                        weight="heavy"
                        fontFamily="headline"
                        className={styles.ctaTitle}
                      >
                        {ctaAttributes.heading}
                      </Text>
                      <Text as="p" maxLines={3} className={styles.ctaDescription}>
                        {ctaAttributes.sub_heading}
                      </Text>
                    </View>
                  </View.Item>
                  <View.Item columns={1} className={styles.superIcon}>
                    <Icon
                      svg={ArrowRight}
                      data-testid="liveTextRightIcon"
                      size={8}
                      color="accent"
                    />
                  </View.Item>
                </View>
              );

              if (isModalLink(ctaAttributes.link)) {
                return (
                  <ModalLink
                    key={ctaAttributes.heading}
                    modalId={ctaAttributes.link}
                    className={styles.singleCtaContainer}
                  >
                    {content}
                  </ModalLink>
                );
              }

              return (
                <View.Item
                  key={ctaAttributes.heading}
                  className={styles.singleCtaContainer}
                  grow
                  as="a"
                  attributes={{
                    href: ctaAttributes.link,
                    // FIXME: this should be replaced with SmartLink once STARC allows passing custom components to View.Item
                    onClick: (event) => {
                      if (ctaAttributes.link.includes('://')) {
                        return;
                      } else {
                        event.preventDefault();
                        void router.push(ctaAttributes.link);
                      }
                    },
                    target: ctaAttributes.link.includes('.pdf') ? '_blank' : '',
                  }}
                >
                  {content}
                </View.Item>
              );
            })}
          </View>
        </View.Item>
      </View>
      <Hidden hide={{ s: true, m: false }}>
        <View className={styles.legalContainer}>{renderLegalComponent()}</View>
      </Hidden>
    </>
  );
};
