/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Grid } from '../../Grid';
import TextField from '../../AZCustomComponent/FormGroup/TextField';
import azCommonStyles from '../../../theme/globals.module.scss';
import { useLabel } from '@/hooks/useLabels';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import styles from './styles.module.scss';
import Headline from './Headline';
import ValidateButton from './ValidateButton';
import Image from '@/components/NextImage';

const confirmationCheck = {
  src: '/images/circle-check.svg',
  alt: 'confirmation check icon',
};

type Props = {
  handleEmailChange?: (e: any) => void;
  validateEmail: () => void;
  signUpSuccess?: boolean;
  lblError?: string;
  errorEmail?: boolean;
  email?: string;
};

const QuickSubscription = ({
  handleEmailChange,
  validateEmail,
  signUpSuccess,
  lblError,
  errorEmail,
  email,
}: Props) => {
  const labels = {
    lblEmail: useLabel('label_ContentBlock_EmailSignup_Email'),
    lblEmailSpecial: useLabel(
      'label_ContentBlock_EmailSignup_ThankYouYourEmailHasBeenAddedToOurEmailListStayTunedForNewsAboutSpecialOffersAndMore'
    ),
  };

  return (
    <DeviceWrapper desktop tablet mobile>
      <Grid
        container
        spacing={0}
        className={styles.loginSection}
        id="quickSubscription"
        justify="space-between"
      >
        <Grid item xs={12} md={7} className={styles.leftContentGray}>
          <Headline />
        </Grid>
        <Grid item xs={12} md={5} sm={12} className={styles.loginHolderGray}>
          <div className={styles.textFieldContainer}>
            {!signUpSuccess ? (
              <TextField
                id="login"
                type="text"
                name="Email"
                value={email}
                onChange={handleEmailChange}
                label={labels.lblEmail}
                meta={{
                  error: lblError,
                  touched: errorEmail,
                }}
                autoComplete="on"
                errorClassName={`${styles.emailErrorStyle} ${styles.emailErrorStyleImportant}`}
                textBoxStyle={styles.textBoxStyle}
                errorLabelStyle={styles.errorLabelStyle}
                inputErrorStyle={styles.inputErrorStyle}
              />
            ) : (
              <div className={styles.successMsgContainer}>
                <h4 className={cx(azCommonStyles['az-title-1-heavy'], styles.successMsg)}>
                  <Image
                    src={confirmationCheck.src}
                    alt={confirmationCheck.alt}
                    className={styles.successMsgIcon}
                    width="30"
                    height="30"
                  />
                  <div>{labels.lblEmailSpecial}</div>
                </h4>
              </div>
            )}
          </div>
          <ValidateButton validateEmail={validateEmail} signUpSuccess={signUpSuccess} />
        </Grid>
      </Grid>
    </DeviceWrapper>
  );
};

export default QuickSubscription;
