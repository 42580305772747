/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export function formatPartTypeId(partTypeId: string | undefined) {
  //recordtype app
  if (partTypeId?.slice(-2) === '-0' || partTypeId?.split('-')[1] === undefined) {
    return partTypeId?.split('-')[0]?.padStart(5, '0');
  }
  //recordtype non-app
  if (partTypeId?.slice(-3) === '-10') {
    return partTypeId?.split('-')[0]?.padStart(10, '0');
  }
}
