/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import {
  CMSSpacer,
  type CMSSpacerProps,
} from '@/features/contentstack/components/CMSSpacer/CMSSpacer';

type Props = {
  children: React.ReactNode;
} & CMSSpacerProps;

const CMSHomeSpacer = ({ children, ...props }: Props) => {
  return (
    <CMSSpacer {...props} homePage>
      {children}
    </CMSSpacer>
  );
};

export { CMSHomeSpacer };
