/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { CertonaItem } from '@/types';
import { type CitrusCarouselItem } from '@/features/citrus';

const getRelativePath = (url: string) => url.replace(/^(?:\/\/|[^/]+)*\//, '/');

export const mapCitrusCarouselToCertonaCarousel = (data: CitrusCarouselItem[]): CertonaItem[] => {
  return data
    .filter(({ customPayload }) => !!customPayload.product_image && !!customPayload.product_link)
    .map(({ customPayload, id: citrusId }) => ({
      id: customPayload.product_sku,
      product_image_url: customPayload.product_image,
      product_name: customPayload.product_name,
      average_rating: customPayload.product_rating_avg,
      isCitrusCard: true,
      product_url_relative: getRelativePath(customPayload.product_link),
      total_review_count: customPayload.product_rating_count,
      citrusId,
    }));
};
