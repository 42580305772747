/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import { Input } from '../../../Input/Input';
import { InputLabel } from '../../../InputLabel/InputLabel';
import { FormHelperText } from '../../../FormHelperText/FormHelperText';
import { FormControl } from '../../../FormControl/FormControl';
import styles from './styles.module.scss';

type TextFieldClasses = {
  input?: string;
};

type Props = {
  maxLength?: number;
  disabled?: boolean;
  helperText?: React.ReactNode;
  id?: string;
  input?: any;
  label?: React.ReactNode;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => unknown;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => unknown;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => unknown;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => unknown;
  meta?: any;
  placeholder?: string;
  required?: boolean;
  type?: string;
  isBorderBottom?: boolean;
  isLeftAligned?: boolean;
  errorMessage?: string;
  tabIndex?: number;
  inputMode?: string;
  name?: string;
  value?: string | number;
  className?: string;
  classes?: TextFieldClasses;
  'data-test'?: string;
  errorClassName?: any;
  textBoxStyle?: any;
  errorLabelStyle?: any;
  inputErrorStyle?: any;
  fullWidth?: boolean;
  normalize?: (value: string) => {};
  autoComplete?: string;
  inputDataTestId?: string;
  outlineLabel?: boolean;
  ariaLabel?: string;
  giftCardLength?: number;
  inputRef?: React.Ref<HTMLInputElement>;
};

export default function CustomTextField(props: Props) {
  const {
    inputRef,
    // Not sure if this needs to be passed to the input
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    maxLength,
    disabled = false,
    helperText = '',
    id = '',
    input,
    label = '',
    meta = { touched: false, error: '' },
    placeholder = '',
    type = 'text',
    required = false,
    isBorderBottom = false,
    isLeftAligned = false,
    errorMessage,
    tabIndex = undefined,
    inputMode = null,
    errorClassName,
    textBoxStyle,
    errorLabelStyle,
    inputErrorStyle,
    fullWidth = true,
    classes = {},
    className,
    inputDataTestId,
    outlineLabel,
    giftCardLength,
    ...params
  } = props;

  const isError = !!meta.error && meta.touched;
  const showHelperText = helperText || isError;
  const idValue = id || input?.name;
  const rootStyles =
    isError || errorMessage
      ? inputErrorStyle || styles.inputError
      : textBoxStyle || (outlineLabel ? styles.inputRootWithOutlined : styles.inputRoot);
  const formControlStyles = disabled ? styles.disabledFormControl : styles.formControl;
  let inputStyle;
  let labelStyle;
  let inputLabel = null;
  let helperLabel = null;
  let helperTextBlock = null;

  if (label) {
    inputStyle = isBorderBottom
      ? styles.inputBorderBottom
      : outlineLabel
      ? styles.inputWithLabelOutlined
      : styles.inputWithLabel;
    labelStyle = isLeftAligned
      ? styles.labelLeftAligned
      : outlineLabel
      ? styles.outlineLabel
      : styles.label;
  } else {
    inputStyle = cx(outlineLabel ? styles.inputOutlined : styles.input, {
      [classes.input || '']: classes.input,
    });
  }

  if (isError) {
    helperLabel = (
      <React.Fragment>
        <span className={styles.srOnly}>{label}</span> {meta.error}
      </React.Fragment>
    );
  }

  if (showHelperText || errorMessage) {
    helperTextBlock = (
      <FormHelperText
        className={errorClassName}
        disabled={disabled}
        error={isError || !!errorMessage}
        id={`${String(idValue)}-helper-text`}
        data-testid={`${String(idValue)}-helper-text`}
        required={required}
        role="alert"
      >
        {helperLabel || helperText || errorMessage}
      </FormHelperText>
    );
  }

  const getMaxLengthValue = (idVal: any) => {
    let maxVal;

    switch (idVal) {
      case 'firstName':
      case 'lastName':
        maxVal = 40;
        break;
      case 'zipCode':
        maxVal = 5;
        break;
      case 'giftCard':
        maxVal = giftCardLength ? giftCardLength : 16;
        break;
      case 'giftCardPin':
        maxVal = 4;
        break;
      default:
        maxVal = 40;
    }

    return maxVal;
  };

  if (label) {
    inputLabel = (
      <InputLabel
        classes={{
          root: labelStyle || '',
          focused: styles.labelFocused,
          shrink: outlineLabel ? styles.labelOutlined : styles.labelShrink,
          disabled: styles.labelDisabled,
          error: errorLabelStyle ? errorLabelStyle : styles.error,
          required: styles.labelRequired,
          asterisk: styles.inputAsterisk,
        }}
        disabled={disabled}
        error={isError || !!errorMessage}
        htmlFor={idValue}
        required={required}
      >
        {label}
      </InputLabel>
    );
  }

  return (
    <FormControl
      className={styles.textField}
      disabled={disabled}
      error={isError || !!errorMessage}
      fullWidth={fullWidth}
      required={required}
    >
      {inputLabel}
      <Input
        inputRef={inputRef}
        data-testid={`text-field-${idValue || ''}`}
        className={cx(rootStyles, className)}
        classes={{
          formControl: formControlStyles,
          input: inputStyle,
        }}
        disableUnderline
        inputProps={{
          ...(inputDataTestId ? { 'data-testid': inputDataTestId } : {}),
          inputMode,
          maxLength: getMaxLengthValue(idValue),
          tabIndex,
        }}
        disabled={disabled}
        error={isError || !!errorMessage}
        id={idValue}
        placeholder={placeholder}
        required={required}
        type={type}
        {...params}
        {...input}
      />
      {helperTextBlock}
    </FormControl>
  );
}
