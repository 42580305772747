/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Grid } from '../../Grid';
import azCommonStyles from '../../../theme/globals.module.scss';
import styles from './styles.module.scss';
import Image from '@/components/NextImage';
import { useLabel } from '@/hooks/useLabels';

const emailEnvelopeWhite = {
  src: '/images/email-envelope.svg',
  alt: 'white email envelope icon',
};

const Headline = () => {
  const labels = {
    lblHeading: useLabel('label_ContentBlock_EmailSignup_GetInTheZone'),
    lblSubHeading: useLabel(
      'label_ContentBlock_Rewards_SubscribeToGetTheLatestDealsPromotionsAndOfferings'
    ),
  };

  return (
    <Grid container justify="flex-start" alignItems="flex-start" className={styles.leftContent}>
      {labels.lblHeading && (
        <Grid item className={styles.elevateItem}>
          <h2 className={cx(azCommonStyles['az-title-1-heavy'], styles.heading)}>
            <Image
              loading="lazy"
              src={emailEnvelopeWhite.src}
              alt={emailEnvelopeWhite.alt}
              className={styles.envelopeIcon}
              width="25"
              height="18"
            />
            {labels.lblHeading}
          </h2>
        </Grid>
      )}
      {labels.lblSubHeading && (
        <Grid item className={styles.elevateItem}>
          <p className={cx(azCommonStyles['az-body-1-regular'], styles.subHeading)}>
            {labels.lblSubHeading}
          </p>
        </Grid>
      )}
    </Grid>
  );
};

export default Headline;
