/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { CMSBanner } from '../CMSBanner/CMSBanner';
import type { ContentStackCitrusAd, SizePerDevice } from '../../interface';
import { mapCitrusBannerToContentStackBanner } from '@/features/citrus/utils/mapCitrusBannerToContentStackBanner';
import { type CitrusAd } from '@/features/citrus';
import { isCitrusBannerWithLink } from '@/features/citrus/utils/isCitrusBannerWithLink';

type Props = {
  content: ContentStackCitrusAd;
  citrusAds?: CitrusAd[];
  priority?: boolean;
  sizePerDevice?: SizePerDevice;
  SpacerComponent: React.ComponentType<{ children: React.ReactNode }>;
};

export function CMSCitrus({
  content,
  citrusAds = [],
  priority,
  sizePerDevice,
  SpacerComponent,
}: Props) {
  const bannersToDisplay = citrusAds.find((adSlot) => adSlot.slotId === content.slot_id);

  return (
    <>
      {bannersToDisplay?.banners.map((item, i) =>
        isCitrusBannerWithLink(item) ? (
          <SpacerComponent key={i}>
            <CMSBanner
              content={mapCitrusBannerToContentStackBanner(item)}
              sponsored
              citrusInfo={{ citrusAdId: item.citrusAdId }}
              priority={priority}
              sizePerDevice={sizePerDevice}
            />
          </SpacerComponent>
        ) : null
      )}
    </>
  );
}
