/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useResponsiveImageData } from '../../hooks/useResponsiveImageData';
import { ContentStackGridBanner } from '../../interface';
import { Picture } from '@/components/Picture/Picture';
import styles from './styles.module.scss';
import cx from 'classnames';
import { GridBannerItem } from './GridBannerItem/GridBannerItem';
import { CMSLink } from '../CMSLink/CMSLink';
import { View } from '@az/starc-ui';

type Props = {
  content: ContentStackGridBanner;
};

const sizePerDevice = {
  desktop: {
    width: 1560,
    height: 466,
  },
  mobile: {
    width: 688,
    height: 755,
  },
  tablet: {
    width: 1110,
    height: 396,
  },
};

type PropsGridContent = {
  gridContent: Props['content']['grid_content'];
  layout: Props['content']['layout'];
  backgroundColor: string | undefined;
};

const GridContent = ({ gridContent, layout, backgroundColor }: PropsGridContent) => {
  const fourItemsGrid = layout === 'Four';
  const customStyles: React.CSSProperties = {
    '--mobile-background-color': backgroundColor,
  } as React.CSSProperties;

  return (
    <div
      style={backgroundColor ? customStyles : undefined}
      className={cx([
        styles.contentGrid,
        fourItemsGrid ? styles.contentGridFour : styles.contentGridSix,
      ])}
    >
      {gridContent?.map((item, index) => (
        <GridBannerItem key={index} item={item} layout={layout} />
      ))}
    </div>
  );
};

export const CMSGridBanner = ({ content }: Props) => {
  const {
    layout,
    grid_content: gridContent,
    link,
    mobile_background_color: backgroundColor,
    image,
  } = content;
  const imageData = useResponsiveImageData({ image: image, sizePerDevice });

  return (
    <View className={styles.cmsGridBanner}>
      <CMSLink to={link} className={styles.cmsGridLink}>
        <Picture
          altText={content.image?.image_alt_text ?? ''}
          desktop={imageData.desktop}
          mobile={imageData.mobile}
          tablet={imageData.tablet}
          imageClassName={styles.backgroundImage}
        />
      </CMSLink>
      <GridContent gridContent={gridContent} layout={layout} backgroundColor={backgroundColor} />
    </View>
  );
};
