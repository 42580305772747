/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import Hidden from '../../Hidden';
import { Button } from '@/components/Button';
import { useLabel } from '@/hooks/useLabels';
import styles from './styles.module.scss';

type Props = {
  signUpSuccess: boolean | undefined;
  validateEmail: () => void;
};

const ValidateButton = ({ signUpSuccess, validateEmail }: Props) => {
  const subscribeLabel = useLabel('label_ContentBlock_EmailSignup_Subscribe');

  return (
    <div>
      <Hidden mdDown>
        {!signUpSuccess ? (
          <Button
            aria-label={subscribeLabel}
            customClass={`${styles.validateBtn} ${styles.arrowTablet}`}
            onClick={validateEmail}
          >
            {''}
          </Button>
        ) : null}
      </Hidden>
      <Hidden lgUp>
        <div className={styles.loginButton}>
          {!signUpSuccess ? (
            <Button
              onClick={validateEmail}
              customClass={styles.arrowTablet}
              aria-label={subscribeLabel}
            >
              {''}
            </Button>
          ) : null}
        </div>
      </Hidden>
    </div>
  );
};

export default ValidateButton;
