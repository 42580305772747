/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import { ContentStackFeaturedSpotlight } from '../../interface';
import NextImage, { blurDataURLPlaceholder } from '@/components/NextImage';
import styles from './CMSFeaturedSpotlight.module.scss';
import { Grid } from '@/components/Grid';
import { CMSLink } from '../CMSLink/CMSLink';

type Props = {
  content: ContentStackFeaturedSpotlight;
};

export function CMSFeaturedSpotlight({ content }: Props) {
  const { feature_content } = content;
  return (
    <Grid container>
      {feature_content?.map(({ link, feature_icon: icon, heading, sub_heading }, i) => {
        const iconSrc = icon?.image_url;
        const iconAlt = icon?.image_alt_text;
        return (
          <Grid key={i} item md={4} xs={12}>
            <CMSLink className={styles.itemContainer} to={link}>
              <NextImage
                className={styles.image}
                alt={iconAlt}
                src={iconSrc ?? blurDataURLPlaceholder}
                width={60}
                height={60}
              />
              <div className={styles.descriptionContainer}>
                <p className={styles.title}>{heading}</p>
                <p className={styles.subTitle}>{sub_heading}</p>
              </div>
            </CMSLink>
          </Grid>
        );
      })}
    </Grid>
  );
}
