/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import PartsCarousel from '@/components/AZCustomComponent/PartsCarousel';
import { useCitrusHomepageCarouselData } from '@/features/citrus/api/getCitrusHomepageCarouselData';
import { useLabel } from '@/hooks/useLabels';
import { mapCitrusCarouselToCertonaCarousel } from '../../utils/mapCitrusCarouselToCertonaCarousel';
import styles from './CMSCitrusCarousel.module.scss';

export function CMSCitrusCarousel() {
  const { data } = useCitrusHomepageCarouselData();
  const sponsoredProductsTitle = useLabel('label_sponsoredproducts');

  if (!data?.ads.length) {
    return null;
  }

  return (
    <PartsCarousel
      partsList={mapCitrusCarouselToCertonaCarousel(data.ads)}
      title={sponsoredProductsTitle}
      isCitrusCard={true}
      pageType={''}
      cardsContainerCustomClass={styles.cardsContainer}
      carouselId={styles.citrusCarousel}
    />
  );
}
